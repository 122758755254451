<template>
    <div id="container">
        <div class="login-error-wrapper">
            <h1 class="login-error-title">ログインエラー</h1>
            <p class="login-error-description">
                参加要件を満たしていないため、ログインできません。
            </p>
            <router-link class="btn-blue shadow" to="/" >TOPへ戻る</router-link>
        </div>
    </div>
</template>
<style lang="scss" src="@/presentation/views/pages/invalid/invalid.scss" scoped></style>